//## For each of Bootstrap's buttons, define text, background and border color.

$body-text-color: #575962;
$white-color: #ffffff;
$black-color: #191919;
$transparent-bg: transparent;
$default-color: #282a3c;
$primary-color: #177dff;
$secondary-color: #716aca;
$info-color: #36a3f7;
$success-color: #35cd3a;
$warning-color: #ffa534;
$danger-color: #f3545d;

$w-color: #20a884;

// Font Weight
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-extrabold: 700;
