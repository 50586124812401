/*     Card     */

.card {
  border-radius: 5px;
  background-color: $white-color;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 1px 15px 1px rgba(69,65,78,0.08);
  -moz-box-shadow: 0px 1px 15px 1px rgba(69,65,78,0.08);
  box-shadow: 0px 1px 15px 1px rgba(69,65,78,0.08);
  border: 0px;
  .card-header {
    padding: 1rem 1.25rem;
    background-color: $transparent-bg;
    border-bottom: 1px solid #ebecec !important;
    &:first-child {
      border-radius: 0px
    }
    .card-head-row{
      display: flex;
      align-items: center;
      .card-tools{
        margin-left: auto;
        float: right;
        padding-left: 15px;
      }
    }
  }
  .separator-solid {
    border-top: 1px solid #ebecec;
    margin: 15px 0;
  }
  .separator-dashed {
    border-top: 1px dashed #ebecec;
    margin: 15px 0;
  }
  .separator-dot {
    border-top: 1px dotted #ebecec;
    margin: 15px 0;
  }
  .full-width-separator {
    margin: 15px -20px 15px;
  }
  .b-b1 {
    border-bottom: 1px solid rgba(255, 255, 255, .3);
  }
  .card-body {
    padding: 1.25rem;
  }
  .card-footer {
    background-color: $transparent-bg;
    line-height: 30px;
    border-top: 1px solid #ebecec !important;
    font-size: 13px;
  }
  .pull-in {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .card-action {
    padding: 30px;
    background-color: $transparent-bg;
    line-height: 30px;
    border-top: 1px solid #ebecec !important;
    font-size: 14px;
  }
  .card-footer {
    hr {
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .legend {
      display: inline-block;
    }
  }
}

.bubble-shadow {
  position: relative;
  &:before {
    position: absolute;
    top: -10%;
    right: -140px;
    width: 300px;
    height: 300px;
    content: "";
    border-radius: 50%;
    background: rgba(255, 255, 255, .05);
  }
  &:after {
    position: absolute;
    top: -10%;
    right: 80px;
    width: 200px;
    height: 200px;
    content: "";
    border-radius: 50%;
    background: rgba(255, 255, 255, .05);
  }
}

.bubble-shadow-small{
  position: relative;
  &:before {
    position: absolute;
    top: -70%;
    right: -40%;
    width: 80px;
    height: 80px;
    content: "";
    border-radius: 50%;
    background: rgba(255, 255, 255, .1);
  }
  &:after {
    position: absolute;
    top: -65%;
    right: 40%;
    width: 70px;
    height: 70px;
    content: "";
    border-radius: 50%;
    background: rgba(255, 255, 255, .1);
  }
}

.card-space {
  padding: 0 30px;
  > .card-header, > .card-body, > .card-footer, > .card-action{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
.card-with-nav{
  .card-header{
    border-bottom: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .card-body{
    padding: 15px 25px !important;
  }
}

.card-list{
  padding: 10px 0;
  .item-list{
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    align-items: center;
    .info-user {
      flex: 1;
      .username, a.username {
        color: $primary-color;
        font-size: 13px;
        margin-bottom: 5px;
        font-weight: $font-weight-normal;
      }
      .status{
        font-size: 11px; 
        color: #7d7b7b;
      }
    }
  }
}

.card-title {
  margin: 0;
  color: $body-text-color;
  font-size: 18px;
  font-weight: $font-weight-normal;
  line-height: 1.6;
  a, a:hover, a:focus {
    color: $body-text-color;
    text-decoration: none;
  }
}
.card-sub {
  display: block;
  margin: 5px 0 10px 0;
  font-size: .8rem;
  background: #f7f8fa;
  color: $body-text-color;
  padding: 0.85rem 1.5rem;
  border-radius: 4px;
  line-height: 1.82;
}
.card-category {
  margin-top: 8px;
  font-size: 13px;
  color: #9A9A9A;
  margin-bottom: 0px;
  word-break: normal;
}
label {
  font-size: 14px;
  font-weight: $font-weight-normal;
  color: #9A9A9A;
  margin-bottom: 0px;
}

.card-transparent{
  background: $transparent-bg;
  box-shadow: none;
  border-color: $transparent-bg;
}

/*     Card Stats    */
.card-stats {
  .card-body{
    padding: 15px !important;
  }
  .card-title{
    margin-bottom: 0px !important;
  }
  .card-category {
    margin-top: 0px;
  }
  .col-icon {
    width: 65px;
    height: 65px;
    margin-left: 15px;
  }
  .icon-big {
    width: 100%;
    height: 100%;
    font-size: 2.2em;
    min-height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.icon-default, &.icon-primary, &.icon-secondary, &.icon-success, &.icon-info, &.icon-warning, &.icon-danger{
      border-radius: 5px;
      i{
        color: $white-color !important;
      }
    }
    &.icon-default {
      background: $default-color;
    }
    &.icon-primary {
      background: $primary-color;
    }
    &.icon-secondary {
      background: $secondary-color;
    }
    &.icon-success {
      background: $success-color;
    }
    &.icon-warning {
      background: $warning-color;
    }
    &.icon-info {
      background: $info-color;
    }
    &.icon-danger {
      background: $danger-color;
    }
    &.round {
      border-radius: 50% !important;
    }
  }
  .col-stats{
    align-items: center;
    display: flex;
    padding-left: 15px;
  }
}

/*     Card Task     */
.card-tasks {
  .table {
    margin-bottom: 0px;
    .form-check {
      padding: 0 0 0 0.75rem !important;
      label {
        margin-bottom: 0px !important;
      }
    }
    tbody td:first-child, thead th:first-child {
      padding-left: 15px;
      padding-right: 15px;
    }
    tbody td:last-child, thead th:last-child {
      padding-right: 15px;
    }
    tbody tr:last-child td {
      border-bottom-width: 0px !important;
    }
  }
  .card-body {
    padding-top: 0px;
    padding-bottom: 0px;
    .table td {
      font-size: 13px;
      .btn {
        font-size: 15px;
        opacity: 0.7;
        transition: all .3s;
      }
      &:hover .btn {
        opacity: 1;
      }
    }
  }
  .form-button-action {
    display: block !important;
  }
}

/*     Card States    */
.card-default, .card-primary, .card-secondary, .card-info, .card-success, .card-warning, .card-danger {
  color: $white-color;
  border: 0px;
}

.card-default .card-header, .card-primary .card-header, .card-secondary .card-header, .card-info .card-header, .card-success .card-header, .card-warning .card-header, .card-danger .card-header {
  border-bottom: $transparent-bg !important;
}

.card-default .card-category, .card-primary .card-category, .card-secondary .card-category, .card-info .card-category, .card-success .card-category, .card-warning .card-category, .card-danger .card-category, .card-default .card-title, .card-primary .card-title, .card-secondary .card-title, .card-info .card-title, .card-success .card-title, .card-warning .card-title, .card-danger .card-title, .card-default label, .card-primary label, .card-info label, .card-success label, .card-warning label, .card-danger label {
  color: $white-color;
}

.card-default .icon-big > i, .card-primary .icon-big > i, .card-secondary .icon-big > i, .card-info .icon-big > i, .card-success .icon-big > i, .card-warning .icon-big > i, .card-danger .icon-big > i {
  color: $white-color !important;
}

.card-default .card-footer, .card-primary .card-footer, .card-secondary .card-footer, .card-info .card-footer, .card-success .card-footer, .card-warning .card-footer, .card-danger .card-footer {
  border-top: $transparent-bg !important;
}

.card-default {
  background: $default-color;
}

.card-primary {
  background: $primary-color;
}

.card-secondary {
  background: $secondary-color;
}

.card-info {
  background: $info-color;
}

.card-success {
  background: $success-color;
}

.card-warning {
  background: $warning-color;
}

.card-danger {
  background: $danger-color;
}

.card-round{
  border-radius: 5px;
}

/*     Progress Card    */

.progress-card {
  margin-bottom: 25px;
  .progress-status{
    display: flex;
    margin-bottom: 10px;
    -webkit-box-pack: justify!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between!important;
  }
}

/*      Card Posts    */
.card-post {
  .info-post {
    .username {
      margin-bottom: 0px;
      font-weight: $font-weight-bold;
    }
    .date{
      margin-bottom: 0px;
    }
  }
}

/*     Card Pricing    */
.card-pricing{
  padding: 20px 5px;
  text-align: center;
  border-radius: 5px;
  .card-header {
    border-bottom: 0px !important;
  }
  .card-footer{
    border-top: 0px !important;
    padding: 15px 15px 10px 15px;
  }
  .card-title{
    font-weight: $font-weight-normal;
    font-size: 20px;
  }
  .card-price{
    .price{
      font-size: 36px;
      font-weight: $font-weight-normal;
    }
    .text{
      font-size: 18px;
      font-weight: $font-weight-normal;
      color: #d1d7e3;
    }
  }
  .specification-list {
    list-style: none;
    padding-left: 0px;
    li {
      padding: 8px 0 12px;
      border-bottom: 1px solid #eee;
      text-align: left;
      font-size: 12px;
      margin-bottom: 5px;
      .name-specification{
        color: #83848a;
      }
      .status-specification{
        margin-left: auto;
        float: right;
        font-weight: $font-weight-normal;
      }
    }
  }
  &.card-pricing-focus{
    padding: 40px 5px;
  }
  &.card-default, &.card-primary, &.card-secondary, &.card-info, &.card-success, &.card-danger, &.card-warning{
    .name-specification{
      color: $white-color !important;
    }
  }
  &.card-primary {
    .specification-list{
      li {
        border-color: #2f8bff;
      }
    }
    .btn-light {
      color: $primary-color !important;
    }
  }
  &.card-success {
    .specification-list{
      li {
        border-color : #64e069;
      }
    }
    .btn-light {
      color: $success-color !important;
    }
  }
  &.card-secondary {
    .specification-list{
      li {
        border-color: #7f77dc;
      }
    }
    .btn-light {
      color: $secondary-color !important;
    }
  }
  &.card-default {
    .specification-list{
      li {
        border-color: #6f8996;
      }
    }
    .btn-light {
      color: $default-color !important;
    }
  }
  &.card-info {
    .specification-list{
      li {
        border-color: #11c0e4;
      }
    }
    .btn-light {
      color: $info-color !important;
    }
  }
  &.card-danger {
    .specification-list{
      li {
        border-color : #ff6972;
      }
    }
    .btn-light {
      color: $danger-color !important;
    }
  }
  &.card-warning {
    .specification-list{
      li {
        border-color : #ffbc67;
      }
    }
    .btn-light {
      color: $warning-color !important;
    }
  }
}

/*     Card Annoucement    */
.card-annoucement {
  .card-body {
    padding: 50px 25px;
  }
  .card-opening {
    font-size: 20px;
    font-weight: $font-weight-normal;
    letter-spacing: 0.01em;
  }
  .card-desc {
    padding: 15px 0;
    font-size: 16px;
    line-height: 1.65;
    font-weight: $font-weight-light;
  }
  &.card-primary {
    .btn-light {
      color: $primary-color !important;
    }
  }
  &.card-success {
    .btn-light {
      color: $success-color !important;
    }
  }
  &.card-secondary {
    .btn-light {
      color: $secondary-color !important;
    }
  }
  &.card-default {
    .btn-light {
      color: $default-color !important;
    }
  }
  &.card-info {
    .btn-light {
      color: $info-color !important;
    }
  }
  &.card-danger {
    .btn-light {
      color: $danger-color !important;
    }
  }
  &.card-warning {
    .btn-light {
      color: $warning-color !important;
    }
  }
}

/*     Card Profile     */
.card-profile {
  background: $white-color !important;
  color: $body-text-color;
  .profile-picture{
    text-align: center;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: -41px;
    width: 100%;
    box-sizing: border-box;
  }
  .user-profile{
    .name{
      font-size: 20px;
      font-weight: $font-weight-normal;
      margin-bottom: 5px;
    }
    .job {
      color: #83848a;
      margin-bottom: 5px;
    }
    .desc{
      color: #bbb;
      margin-bottom: 15px;
    }
    .social-media{
      margin-bottom: 20px;
      .btn{
        padding: 5px !important;
        i {
          font-size: 22px !important;
        }
      }
    }
  }
  .user-stats {
    margin-bottom: 10px;
    [class^="col"]{
      border-right: 1px solid #ebebeb;
    }
    [class^="col"]:last-child{
      border-right: 0px;
    }
    .number {
      font-weight: $font-weight-normal;
      font-size: 15px;
    }
    .title {
      color: #7d7b7b;
    }
  }
  .card-header {
    border-bottom: 0px;
    height: 100px;
    position: relative;
  }
  .card-body{
    padding-top: 60px;
  }
  .card-footer{
    border-top: 0px;
  }
  &.card-secondary{
    .card-header {
      background: $secondary-color;
    }
  }
}

/*      Row Card No Padding      */

.row-card-no-pd {
  border-radius: 5px;
  margin-left: 0;
  margin-right: 0;
  background: $white-color;
  margin-bottom: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
  -webkit-box-shadow: 0px 1px 15px 1px rgba(69,65,78,0.08);
  -moz-box-shadow: 0px 1px 15px 1px rgba(69,65,78,0.08);
  box-shadow: 0px 1px 15px 1px rgba(69,65,78,0.08);
  border: 0px;
  .card {
    margin-bottom: 0px;
    border-width: 0px;
    box-shadow: none;
    position: unset;
    .card-header{
      padding-left: 0px !important;
      padding-top: 0px !important;
      padding-right: 0px !important;
    }
  }
  [class*=col] .card:before {
    position: absolute;
    height: calc(100%);
    width: 1px;
    background: #eee;
    content: '';
    right: 0px;
  }
  [class*=col]:last-child .card:before {
    width: 0px;
  }
}

/*     Accordion     */
.accordion {
  .card{
    border-radius: 5px;
    background: #f7f7f7;
    border: 0;
    box-shadow: none;
    .span-icon{
      font-size: 22px;
      padding-left: 15px;
      padding-right: 15px;
    }
    > .card-header{
      border: 0px !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      > .span-mode{
        margin-left: auto;
        &:before { 
          font-size: 14px;
        }
      }
      &.collapsed {
        > .span-mode{
          &:before { 
            font-size: 14px;
          }
        }
      }
    }
    .card-body{
      border-top: 1px solid #ebebeb;
      padding: 30px;
    }
  }

  %accordion-fontsize {
    font-size: 14px;
  }
  @mixin accordion-style($color) {
    .card-header{
      color: $color; 
      @extend %accordion-fontsize;
      .btn-link{
        color: $color !important; 
        @extend %accordion-fontsize;
      }
    }
  }

  &.accordion-default{
    .card{
      @include accordion-style($default-color);
    }
  }
  &.accordion-primary{
    .card{
      @include accordion-style($primary-color);
    }
  }
  &.accordion-secondary{
    .card{
      @include accordion-style($secondary-color);
    }
  }
  &.accordion-info{
    .card{
      @include accordion-style($info-color);
    }
  }
  &.accordion-success{
    .card{
      @include accordion-style($success-color);
    }
  }
  &.accordion-warning{
    .card{
      @include accordion-style($warning-color);
    }
  }
  &.accordion-danger{
    .card{
      @include accordion-style($danger-color);
    }
  }
}