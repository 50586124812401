div.alert[data-notify="container"] {
    padding-left: 60px;
    z-index: 10100 !important;
}

html.en .form-inline label,
html.fr .form-inline label {
    justify-content: flex-start;
}

#product-info {
    color: $primary-color !important;
    font-weight: bold;
    padding-bottom: 5px;
}

table tr td {
    color: $black-color !important;
}

.table td,
.table th {
    padding: 5px !important;
}

table td.action .dropdown-toggle,
table th.action .dropdown-toggle {
    padding: 3px 9px !important;
}

table tr td p.description {
    padding: 0px;
    padding-left: 10px;
    margin: 0px;
    font-size: 11px;
    color: $body-text-color;
}

.modal-title {
    font-size: 22px;
    font-weight: 600;
}

.btn-info {
    color: #fff !important;
}

.form-label {
    margin-bottom: 0 !important;
}

.form-group,
.form-check {
    margin-bottom: 0;
    padding: 5px 0px;
}

.tar {
    text-align: right;
}

.tac {
    text-align: center;
}

.tal {
    text-align: left;
}

/*    Button    */
.btn {
    border-radius: 0;
}

.page-header .btn-toolbar {
    margin-left: auto;
}

.modal-content {
    border-radius: 0 !important;
    border: 0 !important;
}

table td.action,
table th.action {
    width: 40px;
    text-align: center;
}

table td.action .dropdown-toggle::after,
table th.action .dropdown-toggle::after {
    border: 0;
}

table td.action .dropdown-toggle,
table th.action .dropdown-toggle {
    padding: 5px 9px;
}

table td.action .dropdown-toggle i,
table th.action .dropdown-toggle i {
    font-size: 11px;
}

.radio-group,
.radio-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    width: 100% !important;
}

.radio-group label {
    color: white !important;
}

.radio-group label:not(.active) {
    color: black !important;
    background-color: #f8f9fa !important;
    border-color: #f8f9fa !important;
}

.radio-group input[type="radio"] {
    display: none;
}

/* Select2 */
.select2-dropdown {
    border: 1px solid #ebedf2 !important;
    border-radius: 0.2rem;
}

.select2-container--default .select2-selection--single {
    border: 1px solid #ebedf2 !important;
    height: 31px;
    border-radius: 0.2rem;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #ebedf2 !important;
}

/*     Invoices	    */
.card-invoice .invoice-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.card-invoice .invoice-header .invoice-title {
    font-size: 27px;
    font-weight: 400;
    color: #3a3a3a;
}

.card-invoice .invoice-header .invoice-logo {
    width: 150px;
    display: flex;
    align-items: center;
}

.card-invoice .invoice-header .invoice-logo img {
    width: 100%;
}

.card-invoice .sub {
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 600;
}

.card-invoice .info-invoice {
    padding-top: 15px;
    padding-bottom: 15px;
}

.card-invoice .info-invoice p {
    font-size: 13px;
}

.card-invoice .invoice-desc {
    text-align: right;
    font-size: 13px;
}

.card-invoice .invoice-detail {
    width: 100%;
    display: block;
}

.card-invoice .invoice-detail .invoice-top .title {
    font-size: 20px;
}

.card-invoice .transfer-to .sub {
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 600;
}

.card-invoice .transfer-to .account-transfer > div span:first-child {
    font-weight: 600;
    color: #333;
    font-size: 13px;
}

.card-invoice .transfer-to .account-transfer > div span:last-child {
    font-size: 13px;
    float: right;
}

.card-invoice .transfer-total {
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card-invoice .transfer-total .sub {
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 600;
}

.card-invoice .transfer-total .price {
    font-size: 28px;
    color: #177dff;
    padding: 7px 0;
    font-weight: 600;
}

.card-invoice .transfer-total span {
    font-weight: 600;
    font-size: 13px;
}

.card-invoice .card-body {
    padding: 0;
    border: 0px !important;
    width: 75%;
    margin: auto;
}

.card-invoice .card-header {
    padding: 50px 0px 20px;
    border: 0px !important;
    width: 75%;
    margin: auto;
}

.card-invoice .card-footer {
    padding: 5px 0 50px;
    border: 0px !important;
    width: 75%;
    margin: auto;
}

.accordion .card {
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion .card-header[aria-expanded="false"] .span-mode:before {
    font-family: "Font Awesome 5 Solid";
    font-size: 14px;
    content: "\f078" !important;
}

.accordion .card-header[aria-expanded="true"] .span-mode:before {
    font-family: "Font Awesome 5 Solid";
    font-size: 14px;
    content: "\f077" !important;
}

a.home-card {
    text-decoration: none;
}

.pos-revenus input,
.pos-revenus select {
    font-weight: bold;
    &[readonly="readonly"] {
        color: red;
    }
}
