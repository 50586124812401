/*     Chart Circle    */
.chart-circle {
	display: flex;
	justify-content: center;
	.circles-text {
		font-size: 25px !important;
	}
}

/*     Chart JS Container    */
.chart-container {
	min-height: 300px;
	position: relative;
}

/*     HTML legend    */
.html-legend {
	list-style: none;
	cursor: pointer;
	padding-left: 0;
	text-align: center;
	margin-top: 1rem;
}

.html-legend li {
	display: inline-block;
	vertical-align: middle;
	padding: 0 5px;
	margin-right: 5px;
	margin-bottom: 6px;
	color: #9a9a9a;
	font-size: 12px;
}

.html-legend li.hidden {
	text-decoration: line-through;
}

.html-legend li span {
	border-radius: 15px;
	display: inline-block;
	height: 15px;
	margin-right: 10px;
	width: 15px;
	vertical-align: top;
}

.jqstooltip{
    box-sizing: content-box;
}