/*    Layouts     */

body {
  min-height: 100vh;
  position: relative;
  background: #f9fbfd;
}

.no-bd{
  border: 0px !important;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.wrapper {
  min-height: 100vh;
  position: relative;
  top: 0;
  height: 100vh;
}

.main-header {
  background: $white-color;
  min-height: 55px;
  width: 100%;
  position: fixed;
  z-index: 1001;
  .logo-header {
    float: left;
    width: 240px;
    height: 57px;
    line-height: 55px;
    color: #333333;
    z-index: 1001;
    font-size: 17px;
    font-weight: $font-weight-normal;
    padding-left: 25px;
    padding-right: 25px;
    z-index: 1001;
    display: flex;
    align-items: center;
    position: relative;
    transition: all .3s;
    .big-logo {
      margin-right: 8px;
      &:hover {
        text-decoration: none;
      }
      .logo-img{
        width: 35px;
        height: 35px;
      }
    }
    .logo {
      color: $body-text-color;
      opacity: 1;
      position: relative;
      height: 100%;
      &:hover {
        text-decoration: none;
      }
      .navbar-brand{
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 0px;
      }
    }
    .navbar-minimize{
      position: absolute;
      top: 0;
      right: 18px;
      z-index: 5;
    }
    .navbar-toggler {
      padding-left: 0px;
      padding-right: 0px;
      opacity: 0;
      display: none;
      .navbar-toggler-icon {
        height: 1em;
        width: 1em;
        color: #545454;
        font-size: 22px;
      }
    }
    .more {
      background: $transparent-bg;
      border: 0;
      font-size: 22px;
      padding: 0;
      opacity: 0;
      width: 0;
      display: none;
    }
  }
  .navbar-header {
    min-height: 57px;
  }
  .btn-minimize{
    font-size: 20px;
    line-height: 20px;
    padding: 0px;
    background: transparent !important;
    color: $body-text-color !important;
    &:hover, &:focus {
      opacity: 1;
    }
  }
}

#search-nav {
  flex: 1;
  max-width: 400px;
}

%nav-item-hover-before {
  background: #1d7af3;
  opacity: 1 !important;
  position: absolute;
  z-index: 1;
  width: 3px;
  height: 100%;
  content: '';
  left: 0;
  top: 0;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 240px;
  padding-top: 57px;
  display: block;
  z-index: 1000;
  color: $white-color;
  font-weight: 200;
  background: $white-color;
  -webkit-box-shadow: 1px 0 10px rgba(69, 65, 78, 0.06);
  -moz-box-shadow: 1px 0 10px rgba(69, 65, 78, 0.06);
  box-shadow: 1px 0 10px rgba(69, 65, 78, 0.06);
  transition: all .3s;
  .user {
    margin-top: 13.5px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 12.5px;
    border-bottom: 1px solid #f1f1f1;
    display: block;
    margin-left: 10px;
    margin-right: 10px;
    .info {
      a {
        white-space: nowrap;
        display: block;
        position: relative;
        &:hover, &:focus {
          text-decoration: none;
        }
        > span {
          font-size: 13px;
          font-weight: $font-weight-normal;
          color: #777;
          display: flex;
          flex-direction: column;
          .user-level {
            color: #555;
            font-weight: $font-weight-bold;
            font-size: 11px;
            margin-top: 5px;
          }
        }
        .link-collapse {
          padding: 7px 0;
        }
      }
      .caret {
        position: absolute;
        top: 17px;
        right: 0px;
        border-top-color: #777;
      }
    }
  }
  .sidebar-wrapper {
    position: relative;
    max-height: calc(100vh - 75px);
    min-height: 100%;
    overflow: auto;
    width: 240px;
    z-index: 4;
    padding-bottom: 100px;
    transition: all .3s;
    .sidebar-content{
      padding-top: 0px;
      padding-bottom: 55px;
    }
    .scroll-element.scroll-y{
      top: 5px !important;
    }
  }
  .nav {
    display: block;
    float: none;
    margin-top: 20px;
    .nav-section{
      margin: 15px 0 0 0;
      .sidebar-mini-icon{
        text-align: center;
        font-size: 15px;
        color: rgb(144, 144, 147);
        display: none;
      }
      .text-section{
        padding: 2px 30px;
        font-size: 11px;
        color: #727275;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        margin-bottom: 12px;
        margin-top: 20px;
      }
    }
    > .nav-item {
      display: list-item;
      &.active {
        > a {
          color: $body-text-color !important;
          background: rgba(0, 0, 0, 0.03);
          &:before {
            @extend %nav-item-hover-before;
          }
          p {
            color: $body-text-color !important;
          }
        }
        &:hover > a:before {
          @extend %nav-item-hover-before;
        }
        a i {
          color: #4d7cfe;
        }
      }
      &.submenu{
        background: rgba(0, 0, 0, 0.03);
        > li {
          > a {
            i {
              color: rgba(23, 125, 255, 0.76);
            }
          }
        }
      }
      > a:hover, a:focus {
        background: rgba(0, 0, 0, 0.03);
      }
      a {
        display: flex;
        align-items: center;
        color: #575962;
        padding: 6px 25px;
        width: 100%;
        font-size: 14px;
        font-weight: $font-weight-normal;
        position: relative;
        margin-bottom: 3px;
        &:hover, &:focus {
          text-decoration: none;
          p {
            color: #575962 !important;
          }
          i {
            color: #4d7cfe !important;
          }
        }
      }
      a {
        .letter-icon{
          color: #a1a2a6;
          margin-right: 15px;
          width: 25px;
          text-align: center;
          vertical-align: middle;
          float: left;
          font-size: 20px;
          font-weight: 200;
        }
        i {
          color: #9a9a9a;
          margin-right: 15px;
          width: 25px;
          text-align: center;
          vertical-align: middle;
          float: left;
          &.fas, &.far, &.fab, &.fa {
            font-size: 18px;
            line-height: 30px;
          }
          &[class^="flaticon-"]{
            font-size: 20px;
          }
        }
        p {
          font-size: 13px;
          margin-bottom: 0px;
          margin-right: 5px;
          white-space: nowrap;
          font-weight: $font-weight-bold;
          color: #9a9a9a;
        }
        .caret{
          margin-left: auto;
          margin-right: 10px;
          transition: all .5s;
          color: #9a9a9a;
        }
      }
      a[data-toggle=collapse][aria-expanded=true]{
        background: transparent;
        p {
          color: #575962;
        }
        i {
          color: #4d7cfe;
        }
        .caret {
          filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
          -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
        }
        &:before {
          @extend %nav-item-hover-before;
        }
      }
    }
  }
  .nav-collapse{
    margin-top: 0px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    padding-top: 10px;
    li {
      &.active {
        > a {
          font-weight: 600;
          background: rgba(0,0,0,0.05);
        }
      }
      a:before, a:hover:before{
        opacity: 0 !important;
      }
      a {
        margin-bottom: 0px !important;
        padding: 10px 25px !important;
        .sub-item{
          font-size: 13px;
          position: relative;
          margin-left: 25px;
          opacity: .85;
          &:before{
            content: '';
            height: 4px;
            width: 4px;
            background: rgba(131, 132, 138, 0.89);
            position: absolute;
            left: -15px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 100%;
          }
        }
        &:hover {
          .sub-item {
            opacity: 1;
          }
        }
        .sidebar-mini-icon {
          font-size: 18px;
          color: #C3C5CA;
          margin-right: 15px;
          width: 25px;
          text-align: center;
          vertical-align: middle;
          float: left;
          font-weight: $font-weight-light !important;
        }
      }
    }
    &.subnav {
      padding-bottom: 10px;
      margin-bottom: 0px;
      li {
        a {
          padding-left: 40px !important;
        }
      }
    }
  }
}

.sidebar[data-image] {
  .nav {
    .nav-item {
      opacity: .9;
    }
  }
}

.quick-sidebar{
  position: fixed;
  top: 0;
  bottom: 0;
  right: -380px;
  width: 380px;
  overflow: auto;
  overflow-x: hidden;
  height: 100vh;
  display: block;
  z-index: 1;
  background: $white-color;
  background-size: cover;
  background-position: center center;
  box-shadow: 2px 0px 20px rgba(69, 65, 78, 0.07);
  transition: all .3s;
  z-index: 1101;
  padding: 20px 20px;
  .scroll-wrapper {
    .scroll-element {
      opacity: 0.4 !important;
    }
    &:hover {
      .scroll-element {
        opacity: .8 !important;
      }
    }
  }
  .close-quick-sidebar{
    position: absolute;
    right: 25px;
    color: #999;
  }
  .nav-link {
    padding-top: 0px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-right: 15px !important; 
  }
  .quick-wrapper{
    .quick-scroll{
      height: calc(100vh - 115px);
      overflow: auto;
      margin-bottom: 15px;
    }
    .quick-content{
      padding-bottom: 25px;
    }
    .category-title {
      font-size: 16px;
      font-weight: $font-weight-bold;
      padding-bottom: 10px;
      margin-top: 25px;
      display: block;
      border-bottom: 1px solid #f1f1f1;
      margin-bottom: 15px;
    }
  }
}

.quick-sidebar-overlay{
  position: fixed;
  z-index: 1100;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.63);
}

.main-panel {
  position: relative;
  width: calc(100% - 240px);
  height: 100vh;
  min-height: 100%;
  float: right;
  transition: all .3s;
  > .content {
    padding: 0px !important;
    min-height: calc(100% - 123px);
    margin-top: 57px;
    overflow: hidden;
  }
  > .content-full{
    padding: 0px !important;
    min-height: calc(100% - 123px);
    margin-top: 57px;
    overflow: hidden;
  }
  .page-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .page-title {
      margin-bottom: 0px;
    }
    .btn-page-header-dropdown {
      width: 35px;
      height: 35px;
      font-size: 14px;
      padding: 0px;
      color: #6b6b6b;
      box-shadow: 0 2px 14px 0 rgba(144, 116, 212, 0.1) !important;
      border: 0;
      &:after {
        display: none;
      }
    }
    .dropdown-menu {
      margin-top: 15px;
      top: 0px !important;
      &:after {
        width: 0;
        height: 0;
        border-left: 8px solid $transparent-bg;
        border-right: 8px solid $transparent-bg;
        border-bottom: 8px solid $white-color;
        position: absolute;
        top: -8px;
        right: 32px;
        content: '';
      }
    }
  }
  .page-divider {
    height: 0;
    margin: .3rem 0 1rem;
    overflow: hidden;
    border-top: 1px solid #ebecec;
  }
}

/*      Page Wrapper      */
.page-wrapper {
  min-height: calc(100vh - 57px);
  position: relative;
  &.has-sidebar {
    .page-inner {
      margin-right: 22.5rem;
    }
  }
}

.page-navs {
  position: relative;
  display: block;
  padding-right: 1rem;
  padding-left: 1rem;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,.07);
  z-index: 1;
  .nav {
    .nav-link {
      padding: 1rem !important;
    }
  }
  .nav-line {
    border: 0px !important;
    .nav-link {
      border-bottom-width: 3px !important;
    }
  }
}

.nav-scroller {
  .nav {
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
  }
}

@media (min-width: 992px) {
  .page-navs {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.page-inner {
  padding: 1.5rem 0;
}

@media (min-width: 576px) {
  .page-inner {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

@media (min-width: 992px) {
  .page-inner {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.page-inner-fill {
  padding: 0;
  height: calc(100% - 57px);
  display: flex;
  flex-direction: column;
}

.page-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 22.5rem;
  box-shadow: none;
  background-color: #fff;
  transform: translate3d(100%,0,0);
  overflow: auto;
  z-index: 999;
  transition: transform .2s ease-in-out;
  border-left: 1px solid rgba(61,70,79,.125)!important;
  .back {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem;
    box-shadow: 0 0 0 1px rgba(61,70,79,.05), 0 1px 3px 0 rgba(61,70,79,.15);
    font-size: 15px;
  }
}

.page-sidebar-section {
  flex: 1;
  overflow-y: auto;
}

@media (min-width: 1200px) {
  .page-sidebar {
    transform: translateZ(0);
  }
}

@media (max-width: 1200px) {
  .page-wrapper {
    &.has-sidebar {
      .page-inner {
        margin-right: 0px;
      }
    }
  }
  .pagesidebar_open {
    .page-sidebar {
      transform: translate3d(0, 0, 0) !important;
      max-width: unset;
    }
  }
}

.page-with-aside{
  display: flex;
  .page-aside{
    background: $white-color;
    width: 280px;
    min-height: 100vh;
    border-right: 1px solid #f1f1f1;
    border-left: 1px solid #f1f1f1;
    padding: 15px 0;    
    .aside-header{
      padding: 15px 22px;
      .title{
        font-size: 24px;
      }
      .description{
        font-size: 12px;
      }
    }
    .aside-nav{
      .nav{
        flex-direction: column;
        > li {
          padding: 8px 22px;
          margin-bottom: 5px;
          &:hover, &:focus, &.active {
            background: #fafafa;
          } 
          &.active{
            padding: 12px 22px;
            font-weight: $font-weight-bold;
            > a {
              color: #575962 !important;
            }
          }
          > a {
            color: #83848a;
            display: flex;
            align-items: center;
            font-size: 12px;
            &:hover, &:focus{
              text-decoration: none;
            }
            i {
              font-size: 20px;
              margin-right: 15px;
              color: #a1a2a6;
            }
          }
        }
      }
      .label {
        padding: 5px 22px;
        margin-top: 22px;
        margin-bottom: 5px;
        display: block
      }
    }
    .aside-compose{
      padding: 25px 22px;
    }
  }
  .page-content{
    width: calc(100% - 280px);
  }
}

.footer {
  border-top: 1px solid #eee;
  padding: 15px;
  background: $white-color;
  position: absolute;
  width: 100%;
  .container-fluid {
    display: flex;
    align-items: center;
  }
}

//sidebar minimized 
@media screen and (min-width: 991px) {
  .sidebar_minimize{
    .main-panel {
      width: calc(100% - 75px);
      transition: all .3s;
    }
    .logo-header {
      width: 75px;
      transition: all .3s;
      padding: 0px;
      text-align: center;
      .big-logo {
        margin-right: 0px;
      }
      .logo {
        position: absolute;
        transform: translate3d(25px,0,0);
        opacity: 0;
        img {
          display: none;
        }
      }
    }
    .navbar-minimize {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      right: 0 !important;
    }
    .sidebar{
      width: 75px;
      transition: all .3s;
      .sidebar-wrapper{
        width: 75px;
        transition: all .3s;
        .user {
          padding-left: 0px;
          padding-right: 0px;
          [class^="avatar-"] {
            float: none !important;
            margin: auto;
          }
          .info{
            display: none;
            span {
              display: none;
            }
          }
        }
        .nav-item {
          position : relative;
          a {
            .letter-icon {
              display: block !important;
            }
            .badge, span, .caret, p {
              display: none;
              transition: all .3s;
            }
            .sidebar-mini-icon{
              display: block !important;
              margin-right: 0px;
            }
          }
          &.submenu, &.active{
            .nav-collapse {
              display: none;
            }
          }
        }
        .nav-section{
          .text-section{
            display: none;
          }
          .sidebar-mini-icon{
            display: block;
          }
        }
      }
    }
    .sidebar:hover{
      width: 240px;
      .sidebar-wrapper{
        width: 240px;
        .user {
          padding-left: 15px;
          padding-right: 15px;
          [class^="avatar-"] {
            float: left !important;
            margin-right: 11px !important;
          }
          .info{
            display: block;
            span {
              display: block;
            }
          }
        }
        .nav-item {
          a {
            .badge, span, .caret, p {
              display: block;
            }
            .sidebar-mini-icon{
              display: block !important;
              margin-right: 15px;
            }
          }
          &.submenu, &.active{
            .nav-collapse {
              display: block;
            }
          }
        }
        .nav-section{
          .sidebar-mini-icon {
            display: none;
          }
          .text-section{
            display: block;
          }
        }
      }
    }
    &.sidebar_minimize_hover {
      .logo-header{
        width: 240px;
        padding-left: 25px;
        padding-right: 25px;
        text-align: left;
        .big-logo {
          margin-right: 8px;
        }
        .logo {
          opacity: 1 !important;
          transform: translate3d(0, 0, 0) !important;
          position: relative !important;
          img {
            display: inline-block !important;
          }
        }
      }
      .main-panel {
        width: calc(100% - 240px);
      }
    }
  }
  .sidebar_minimize_hover {
    .navbar-minimize {
      right: 18px !important;
      transform: translateX(0%) !important;
      left: unset;
    }
  }
}

.quick_sidebar_open{
  .quick-sidebar {
    right: 0px !important;
  }
}

/*    Flex-1    */

.flex-1 {
  -ms-flex: 1;
  flex: 1;
}

/*    Metric    */
.metric {
  display: flex;
  padding: 1rem;
  flex-direction: column;
}
