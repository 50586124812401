/*     Nav Pill     */

.nav-pills > li {
  &:first-child > .nav-link {
    border-radius: 4px 0 0 4px !important;
  }
  &:last-child > .nav-link {
    border-radius: 0 4px 4px 0 !important;
  }
}

.nav-link.disabled {
  color: #6c757d !important;
}

.nav-pills {
  .nav-link {
    padding: 10px 20px;
  }
  > li > .nav-link {
    margin-left: -1px;
    border-radius: 0 !important;
    margin-top: 5px;
    margin-bottom: 5px;
    &.active {
      background: $primary-color;
    }
    border: 1px solid $primary-color;
    color: #585c5d;
    &:hover {
      background: rgba(222, 222, 222, 0.4);
    }
  }
  &.nav-pills-no-bd{
    li {
      margin-left: 15px !important;
      .nav-link{
        border: 0px !important;
        border-radius: 50px !important;
        background: rgba(222, 222, 222, 0.4);
        &.active{
          border-radius: 50px !important;
        }
      }
      &:first-child{
        margin-left: 0px !important;
      }
    }
    &.nav-pills-icons{
      .nav-link, .nav-link.active{
        border-radius: 5px !important;
      }
    }
  }
  &.flex-column {
    .nav-link {
      border-radius: 0 !important;
      border: 1px solid $primary-color;
      color: #585c5d;
      margin-top: -1px;
      text-align: center;
      word-wrap: normal;
      padding: 10px 0;
      &:hover {
        background: rgba(222, 222, 222, 0.4);
      }
      &.active {
        background: $primary-color;
      }
      &:first-child {
        border-radius: 4px 4px 0 0 !important;
      }
      &:last-child {
        border-radius: 0 0 4px 4px !important;
      }
    }
    &.nav-pills-no-bd{
      .nav-link{
        border: 0px !important;
        border-radius: 50px !important;
        background: rgba(222, 222, 222, 0.4);
        margin-top: 5px;
        margin-bottom: 5px;
        &.active{
          border-radius: 50px !important;
        }
      }
    }
    &.nav-pills-icons{
      .nav-link, .nav-link.active{
        border-radius: 5px !important;
      }
    }
  }
  &.nav-pills-icons{
    .nav-link, .nav-link.active{
      border-radius: 5px !important;
      padding-top: 12px;
      padding-bottom: 12px;
    }
    i {
      display: block;
      text-align: center;
      font-size: 2em;
      line-height: 50px;
    }
  }
  &.nav-default, &.nav-primary, &.nav-secondary, &.nav-info, &.nav-warning, &.nav-success, &.nav-danger{
    .nav-link {
      border: 1px solid #eeeeee;
    }
    .nav-link.active {
      color: $white-color !important;
    }
  }

  @mixin nav-pills-states-active($color) {
    background: $color;
    border: 1px solid $color;
  }

  &.nav-default .nav-link {
    &.active {
      @include nav-pills-states-active($default-color);
    }
  }
  &.nav-primary .nav-link {
    &.active {
      @include nav-pills-states-active($primary-color);
    }
  }
  &.nav-secondary .nav-link {
    &.active {
      @include nav-pills-states-active($secondary-color);
    }
  }
  &.nav-info .nav-link {
    &.active {
      @include nav-pills-states-active($info-color);
    }
  }
  &.nav-success .nav-link {
    &.active {
      @include nav-pills-states-active($success-color);
    }
  }
  &.nav-warning  .nav-link {
    &.active {
      @include nav-pills-states-active($warning-color);
    }
  }
  &.nav-danger  .nav-link {
    &.active {
      @include nav-pills-states-active($danger-color);
    }
  }
}

/* Size Nav */
.nav-sm{
  .nav-link{
    font-size: 11px !important;
    padding: 8px 16px !important;
  }
}