// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~jquery-ui/themes/base/all.css';

// Select2
@import 'select2'; 

/*  Belbouk  */
@import "variables";
@import "background";
@import "outlinefocus";
@import "typography";

//layouts
@import "layouts";
@import "layouts-colors";

//icons
// @import "azzara/font-awesome";
// @import "azzara/flaticon";

//components
@import "components";

//plugins
@import "plugins";

//responsive
@import "responsive";

//404
@import "pagenotfound";


//Login
@import "login";

@import "custom";
