/*   Typography    */

body,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.navbar,
.brand,
.btn-simple,
.alert,
a,
.td-name,
td,
button.close {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: 'Open Sans', sans-serif;
}

body {
    font-size: 13px;
    color: $body-text-color;
}

/*

h1,
.h1
{
  font-size: 1.625rem;
}

h2,
.h2
{
  font-size: 1.25rem;
}

h3,
.h3
{
  font-size: 1.0625rem;
}

h4,
.h4
{
  font-size: .9375rem;
}

h5,
.h5
{
  font-size: .8125rem;
}

h6,
.h6
{
  font-size: .725rem;
}
*/

p {
    font-size: 13px;
    line-height: 1.82;
    margin-bottom: 1rem;
    word-break: break-word;
}

small,
.small {
    font-size: 12px;
}

b,
.b,
strong,
.strong {
    font-weight: $font-weight-bold;
}

.page-pretitle {
    letter-spacing: .08em;
    text-transform: uppercase;
    color: #95aac9;
}

.page-title {
    font-size: 22px;
    font-weight: $font-weight-bold;
    color: #444444;
    line-height: 30px;
    margin-bottom: 20px;
}

.page-category {
    color: #444444;
    line-height: 1.8;
    margin-bottom: 25px;
}

.text-primary,
.text-primary a {
    color: $primary-color !important;

    &:hover {
        color: $primary-color !important;
    }
}

.text-secondary,
.text-secondary a {
    color: $secondary-color !important;

    &:hover {
        color: $secondary-color !important;
    }
}

.text-info,
.text-info a {
    color: $info-color !important;

    &:hover {
        color: $info-color !important;
    }
}

.text-success,
.text-success a {
    color: $success-color !important;

    &:hover {
        color: $success-color !important;
    }
}

.text-warning,
.text-warning a {
    color: $warning-color !important;

    &:hover {
        color: $warning-color !important;
    }
}

.text-danger,
.text-danger a {
    color: $danger-color !important;

    &:hover {
        color: $danger-color !important;
    }
}

label {
    color: #495057 !important;
    font-size: 12px !important;
}

.metric-value {
    margin-bottom: 5px;
    line-height: 1;
    white-space: nowrap;
}

.metric-label {
    font-size: .875rem;
    font-weight: 500;
    color: #686f76;
    white-space: nowrap;
    margin-bottom: 0;
}

/*   Font-weight    */

.fw-light {
    font-weight: $font-weight-light !important;
}

.fw-normal {
    font-weight: $font-weight-normal !important;
}

.fw-mediumbold {
    font-weight: $font-weight-normal !important;
}

.fw-bold {
    font-weight: $font-weight-bold !important;
}

.fw-b {
    font-weight: bold !important;
}

.fw-extrabold {
    font-weight: $font-weight-extrabold !important;
}
