/*     	Setting Tab     */
.settings-wrapper{
	.settings-content{
		.settings-list{
			padding-left: 0px;
			list-style: none;
			li{
				display: table;
				width: 100%;
				margin-bottom: 15px;
				.item-label{
					display: table-cell;
					vertical-align: middle;
					font-size: 12px;
				}
				.item-control{
					display: table-cell;
					float: right;
					margin-right: 5px;
					.toggle-group {
						.toggle-on, .toggle-off{
							font-size: 11px !important;
						}
					}
				}
			}
		}
	}
}