.avatar {
  position: relative;
  display: inline-block;
}

.avatar-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.avatar-title {
  display: flex;
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: $primary-color;
  align-items: center;
  justify-content: center;
}

.avatar-online::before, .avatar-offline::before, .avatar-away::before {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30%;
  height: 30%;
  content: '';
  border: 3px solid #fff;
  border-radius: 50%;
}

.avatar-online::before {
  background-color: $success-color;
}

.avatar-offline::before {
  background-color: #b1c2d9;
}

.avatar-away::before {
  background-color: $warning-color;
}

.avatar {
  width: 3rem;
  height: 3rem;
  .border {
    border-width: 4px !important;
  }
  .rounded {
    border-radius: 6px !important;
  }
  .avatar-title {
    font-size: 18px;
  }
  &.avatar-online::before, &.avatar-offline::before, &.avatar-away::before {
    border-width: 3px;
  }
}

.avatar-xs {
  width: 1.625rem;
  height: 1.625rem;
  .border {
    border-width: 2px !important;
  }
  .rounded {
    border-radius: 4px !important;
  }
  .avatar-title {
    font-size: 10px;
  }
  &.avatar-online::before, &.avatar-offline::before, &.avatar-away::before {
    border-width: 1px;
  }
}

.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
  .border {
    border-width: 3px !important;
  }
  .rounded {
    border-radius: 4px !important;
  }
  .avatar-title {
    font-size: 13px;
  }
  &.avatar-online::before, &.avatar-offline::before, &.avatar-away::before {
    border-width: 2px;
  }
}

.avatar-lg {
  width: 4rem;
  height: 4rem;
  .border {
    border-width: 3px !important;
  }
  .rounded {
    border-radius: 8px !important;
  }
  .avatar-title {
    font-size: 24px;
  }
  &.avatar-online::before, &.avatar-offline::before, &.avatar-away::before {
    border-width: 3px;
  }
}

.avatar-xl {
  width: 5.125rem;
  height: 5.125rem;
  .border {
    border-width: 4px !important;
  }
  .rounded {
    border-radius: 8px !important;
  }
  .avatar-title {
    font-size: 30px;
  }
  &.avatar-online::before, &.avatar-offline::before, &.avatar-away::before {
    border-width: 4px;
  }
}

.avatar-xxl {
  width: 5.125rem;
  height: 5.125rem;
  .border {
    border-width: 6px !important;
  }
  .rounded {
    border-radius: 8px !important;
  }
  .avatar-title {
    font-size: 30px;
  }
  &.avatar-online::before, &.avatar-offline::before, &.avatar-away::before {
    border-width: 4px;
  }
}

@media (min-width: 768px) {
  .avatar-xxl {
    width: 8rem;
    height: 8rem;
    .border {
      border-width: 4px !important;
    }
    .rounded {
      border-radius: 12px !important;
    }
    .avatar-title {
      font-size: 42px;
    }
    &.avatar-online::before, &.avatar-offline::before, &.avatar-away::before {
      border-width: 4px;
    }
  }
}

.avatar-group {
  display: inline-flex;
  .avatar + .avatar {
    margin-left: -.75rem;
  }
  .avatar-xs + .avatar-xs {
    margin-left: -.40625rem;
  }
  .avatar-sm + .avatar-sm {
    margin-left: -.625rem;
  }
  .avatar-lg + .avatar-lg {
    margin-left: -1rem;
  }
  .avatar-xl + .avatar-xl {
    margin-left: -1.28125rem;
  }
  .avatar:hover {
    z-index: 1;
  }
}