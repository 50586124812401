/*    Button    */

.btn {
  padding: .6rem 1rem;
  font-size: 13px;
  opacity: 1;
  border-radius: 3px;
  &:hover, &:focus {
    opacity: 0.9;
    transition: all .3s;
  }
  .btn-label{
    display: inline-block;
    i {
      font-size: 16px;
      vertical-align: middle;
      margin-right: 2px;
      margin-left: -2px;
      line-height: 0;
      margin-top: -2.5px;
    }
    &.just-icon i{
      margin-left: 0 !important;
      margin-right: 0px !important;
    }
  }
}

/*     Button Sizes     */
.btn-lg {
  font-size: 15px;
  border-radius: 3px;
  padding: 10px 25px;
  font-weight: $font-weight-normal;
  .btn-label{
    i {
      font-size: 27px;
      vertical-align: middle;
      margin-right: 2px;
      margin-left: -7px;
      line-height: 0;
      margin-top: -2.5px;
    }
    &.just-icon i{
      margin-left: 0 !important;
      margin-right: 0px !important;
    }
  }
}

.btn-sm {
  font-size: 11px;
  padding: 7px 13px;
}

.btn-xs {
  font-size: 10px;
  padding: 5px 9px;
}

.btn {
  &.disabled:hover, &:hover:disabled {
    opacity: 0.65;
  }
}

/*      Button Icon        */
.btn-icon{
  font-size: .9375rem;
  height: 2.5125rem;
  line-height: normal;
  min-width: 2.5125rem;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 2.5125rem;
  &.btn-lg {
    height: 2.75rem;
    min-width: 2.75rem;
    width: 2.75rem;
  }
  &.btn-sm {
    height: 2rem;
    min-width: 2rem;
    width: 2rem;
  }
  &.btn-xs {
    height: 1.6875rem;
    min-width: 1.6875rem;
    width: 1.6875rem;
  }
}

/*      Button States      */
.btn-default {
  background: $default-color !important;
  color: $white-color !important;
  &:hover, &:focus, &:disabled {
    background: $default-color !important;
    color: $white-color !important;
  }
}

.btn-primary {
  background: $primary-color !important;
  border-color: $primary-color !important;
  &:hover, &:focus, &:disabled {
    background: $primary-color !important;
    border-color: $primary-color !important;
  }
}

.btn-secondary {
  background: $secondary-color !important;
  border-color: $secondary-color !important;
  &:hover, &:focus, &:disabled {
    background: $secondary-color !important;
    border-color: $secondary-color !important;
  }
}

.btn-info {
  background: $info-color !important;
  border-color: $info-color !important;
  &:hover, &:focus, &:disabled {
    background: $info-color !important;
    border-color: $info-color !important;
  }
}

.btn-success {
  background: $success-color !important;
  border-color: $success-color !important;
  &:hover, &:focus, &:disabled {
    background: $success-color !important;
    border-color: $success-color !important;
  }
}

.btn-warning {
  background: $warning-color !important;
  border-color: $warning-color !important;
  color: $white-color !important;
  &:hover, &:focus, &:disabled {
    background: $warning-color !important;
    border-color: $warning-color !important;
    color: $white-color !important;
  }
}

.btn-danger {
  background: $danger-color !important;
  border-color: $danger-color !important;
  &:hover, &:focus, &:disabled {
    background: $danger-color !important;
    border-color: $danger-color !important;
  }
}

.btn-light{
  background: $white-color !important;
  border-color: transparent;
  &:hover, &:focus, &:disabled {
    background: #ebecec !important;
    border-color: transparent;
  }
}

.btn-dropdown-card-header {
  padding: 0;
  background: transparent;
  color: inherit;
  font-size: 15px;
  &:after {
    display: none;
  }
}

/*      Button Border     */
@mixin btn-border($color) {
  color: $color !important;
  border: 1px solid $color !important;  
}

.btn-border {
  background: $transparent-bg !important;
  &:hover, &:focus {
    background: $transparent-bg !important;
  }
  &.btn-default {
    @include btn-border($default-color);
  }
  &.btn-primary {
    @include btn-border($primary-color);
  }
  &.btn-secondary {
    @include btn-border($secondary-color);
  }
  &.btn-info {
    @include btn-border($info-color);
  }  
  &.btn-success {
    @include btn-border($success-color);
  }
  &.btn-warning {
    @include btn-border($warning-color);
  }

  &.btn-danger {
    @include btn-border($danger-color);
  }
  &.btn-light {
    border: 1px solid #efefef;
    background: #fff !important;
  }
}

/*      Button Rounded      */
.btn-round {
  border-radius: 100px !important;
}

/*      Button Link      */
@mixin btn-link($color) {
  color: $color !important;
  &:hover {
    color: $color !important;
  }
}

.btn-link {
  border: 0 !important;
  background: $transparent-bg !important;
  &:hover, &:focus {
    text-decoration: underline !important;
    background: $transparent-bg !important;
    border: 0 !important;
  }

  &.btn-default {
    @include btn-link($default-color);
  }

  &.btn-primary {
    @include btn-link($primary-color);
  }

  &.btn-secondary {
    @include btn-link($secondary-color);
  }

  &.btn-info {
    @include btn-link($info-color);
  }

  &.btn-success {
    @include btn-link($success-color);
  }

  &.btn-warning {
    @include btn-link($warning-color);
  }

  &.btn-danger {
    @include btn-link($danger-color);
  }
}


.toggle-on.btn {
  color: $white-color !important;
}

.toggle-handle {
  background: $white-color !important;
  &:hover {
    background: $white-color !important;
  }
}

.btn-round .toggle-handle {
  border-radius: 50px;
}

.btn-rounded {
  border-radius: 60px !important;
}

.btn-full {
  width: 100%;
}

.btn-no-radius {
  border-radius: 0px;
}