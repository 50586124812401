/*     	Popover     */
.popover {
	max-width: 240px;
	line-height: 1.7;
	border: 0;
	box-shadow: 0px 0px 20px 1px rgba(69, 65, 78, 0.2);
	.popover-header{
		background: $transparent-bg;
		font-size: 14px;
		border-bottom: 0px;
		text-transform: capitalize;
		margin-top: 5px;
		color: #aaaaaa;
		font-weight: $font-weight-normal;
	}
	.popover-body {
		margin-bottom: 5px;
		p {
			font-size: 13px;
			margin-bottom: 1rem;
		}
	}
	&.bs-popover-top, &.bs-popover-bottom, &.bs-popover-left, &.bs-popover-right{
		.arrow{
			&:before {
				border: $transparent-bg;
			}
		}
	}
}

.popover.bs-popover-auto[x-placement^=right], .popover.bs-popover-right{
	margin-left:10px;
}

.popover.bs-popover-auto[x-placement^=left], .popover.bs-popover-left{
	margin-right:10px;
}

.popover.bs-popover-auto[x-placement^=top], .popover.bs-popover-top{
	margin-bottom:10px;
}

.popover.bs-popover-auto[x-placement^=bottom], .popover.bs-popover-bottom{
	margin-top:10px;
}