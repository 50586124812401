/*     Nav Search     */

.nav-search {
  .input-group {
    border: 1px solid #eee;
    background: #eee;
    border-radius: 5px;
    &:hover, &:focus {
      border: 1px solid #ddd;
    }
  }
  .form-control {
    border: 0;
    background: $transparent-bg;
    font-size: 13px;
    padding: 0.75em 1em;
    min-width: 225px;
    max-width: 100%;
  }
  .input-group-text {
    border: 0;
    background: $transparent-bg;
  }
  .search-icon {
    font-size: 18px;
    color: #666;
  }
  .btn-search {
    background: $transparent-bg;
    padding: .375rem 1rem;
  }
}